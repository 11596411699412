/***************************Laptop View**********************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: 'Poppins', sans-serif;

}

.navbar {
  position: fixed;
  left: 0;
  height: 12vh;
  display: flex;
  font-size: 1.3rem;
  font-family: sans-serif;
  justify-content: space-between;
  position: sticky;
  align-items: center;
  top: 0;
  z-index: 9999;
  padding: 0 4rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.navbar a {
  color: black;
  cursor: pointer;
}

.navbar::before {
  content: "";
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.logo img {
  width: 12%;
}

.menu {
  width: 49%;
}

.menu ul {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0 2rem;
  color: black;
  font-size: 1rem;
}

.button {
  border: none;
  background: #e279e7;
  border-radius: 100px;
  padding: 0.8rem 1.5rem;
}

.button:hover {
  background: rgb(13, 0, 14);
  cursor: pointer;
}

.button-text {
  text-align: center;
  color: white;
}

.hamburger {
  display: none;
}

.menu .logos {
  display: none;
}

/* ******************* Mobile View ********************* */

@media (max-width: 1023px) {
  .button {
    display: none;
  }

  .menu {
    display: none;
  }

  .logo img {
    width: 70%;
  }

  .hamburger {
    display: block;
    font-size: 2.5rem;
    padding-top: 10px;
    color: white;
  }

  body.menu-open {
    overflow-y: hidden;
  }

  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px 40px;
    z-index: 9999;
  }

  .mobile-menu ul {
    position: fixed;
    top: 12vh;
    left: 0;
    height: calc(100vh - 12vh);
    /* Adjusted to subtract the height of the navbar */
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    font-size: 1.5rem;
    color: linear-gradient(to bottom, #ffb6fa, #87ceeb);
    font-family: sans-serif;
    font-weight: 400;
    background-color: rgb(255, 255, 255);
    z-index: 9998;
    overflow-y: auto;
  }

  .mobile-menu {
    display: none;
    width: 98%;
    background-color: transparent;
    display: grid;
    grid-column: 3/5;
    align-items: center;
    transition: all 3s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px;
    overflow-y: auto;
    z-index: 9997;
  }

  .mobile-menu ul li:first-child {
    transition-delay: 3s;
  }

  .hamburger:focus+.mobile-menu,
  .hamburger:checked+.mobile-menu {
    display: grid;
  }

  .mobile-menu ul li {
    transition: opacity 1s ease;
    color: black;
  }

  .mobile-menu ul li a {
    color: black;
  }

  .mobile-menu .logos {
    display: block;
    height: 5rem;
    display: flex;
    justify-content: space-around;
  }

  .mobile-menu .insta {
    font-size: 2rem;
  }
}

@media (min-width:606px) and (max-width: 1023px) {
  .logo img {
    width: 15%;
  }
}
@media (min-width:330px) and (max-width: 605px) {
  .logo img {
    width: 20%;
  }
}
@media (min-width:10px) and (max-width: 329px) {
  .logo img {
    width: 25%;
  }
}