/* laptop veiw */
@media (min-width: 1024px) {
/* ===============================About me====================================== */
.hero {
  background: linear-gradient(to bottom, #ffb6fa, #a20097);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 3rem 0rem;
}

.hero div {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

.intro {
  font-size: 1rem;
  font-weight: 300;
  width: 50%;
  color: white;
}

.intro-hl {
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 400;
  color: #e340f1;
}

.hero img {
  width: 30%;
}

.heading {
  font-family: 'Lobster', cursive;
  font-size: 2rem;
  color: #ef79fa;
}

.heading1 {
  font-family: 'Lobster', cursive;
  font-size: 2rem;
  color: black;
}


/* =====================================Gallery======================================= */
.Gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0rem;
  gap: 3rem;
}

.Gallery div {
  width: 80%;
  margin: 0 auto;
}

.carousel-div {
  box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
}

.imgslide{
  width: 90%;
  aspect-ratio: 2/3;
  object-fit: contain;
}

.btn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13rem;
  height: 3rem;
  background-size: 300% 300%;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
  transition: 0.5s;
  animation: gradient_301 5s ease infinite;
  border: double 4px transparent;
  background-image: linear-gradient(#212121, #212121), linear-gradient(137.48deg, #ffdb3b 10%, #FE53BB 45%, #8F51EA 67%, #0044ff 87%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

#container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
}

strong {
  z-index: 2;
  font-family: 'Avalors Personal Use';
  font-size: 12px;
  letter-spacing: 5px;
  color: #FFFFFF;
  text-shadow: 0 0 4px white;
}

#glow {
  position: absolute;
  display: flex;
  width: 12rem;
}

.circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.circle:nth-of-type(1) {
  background: rgba(254, 83, 186, 0.636);
}

.circle:nth-of-type(2) {
  background: rgba(142, 81, 234, 0.704);
}

.btn1:hover #container-stars {
  z-index: 1;
  background-color: #212121;
}

.btn1:hover {
  transform: scale(1.1)
}

.btn1:active {
  border: double 4px #FE53BB;
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: none;
}

.btn1:active .circle {
  background: #FE53BB;
}

#stars {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

#stars::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

#stars::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

#stars::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

#stars::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/*=========================================== Menus ===============================================*/
.Menus {
  background: linear-gradient(to bottom, #ffb6fa, #040000);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 3rem 0rem;

}

.Menus>div {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.Menus>div>img {
  margin: 0 auto;
  width: 70%;
  border-radius: 1rem;
  box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
}

/* ======================================= Application ======================================= */
.Application {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 5rem 0rem;

}

/* ======================================== Contact ============================================= */
.Contact {

  background: linear-gradient(to bottom, #ffb6fa, #040000);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 4rem 0rem;
}
.Contact>div{
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  gap: 2rem 0rem;
}
.Contact>div>div{
  text-align: center;
  width: 60%;
  margin: auto;
}
.Contact>div>div>h1{
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  color: white;
}
.logos{
  text-decoration: none;
  color: white;
  font-size: 2rem;
}
.logos:hover{
  color: #e162f1;
}

}

/* ============================================Tablet veiw =========================================*/

@media(min-width : 641px) and (max-width:1023px) {
    /* ===============================About me====================================== */
    .hero {
      background: linear-gradient(to bottom, #ffb6fa, #a20097);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      padding: 3rem 0rem;
    }
    
    .hero div {
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4rem;
    }
    
    .intro {
      font-size: 1rem;
      font-weight: 300;
      width: 100%;
      text-align: center;
      color: white;
    }
    
    .intro-hl {
      font-size: 1.2rem;
      font-style: italic;
      font-weight: 400;
      color: #e340f1;
    }
    
    .hero img {
      width: 55%;
    }
    
    .heading {
      font-family: 'Lobster', cursive;
      font-size: 2rem;
      color: #ef79fa;
    }
    
    .heading1 {
      font-family: 'Lobster', cursive;
      font-size: 2rem;
      color: black;
    }
    
    
    /* =====================================Gallery======================================= */
    .Gallery {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5rem;
      padding: 4rem 0rem;
    }
    
    .Gallery div {
      width: 80%;
      margin: 0 auto;
    }
    
    .carousel-div {
      box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
    }
    
    .imgslide{
      width: 90%;
      aspect-ratio: 2/3;
      object-fit: contain;
    }
    .Gallery button{
     color: white;
     background-color: #ef79fa;
     border: none;
     border-radius: 1rem;
     padding: 1rem 4rem;
    }
    
   
    
    /*=========================================== Menus ===============================================*/
    .Menus {
      background: linear-gradient(to bottom, #ffb6fa, #040000);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 3rem;
      padding: 3rem 0rem;
    }
    
    .Menus>div {
      width: 80%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;
    }
    
    .Menus>div>img {
      margin: 0 auto;
      width: 85%;
      border-radius: 1rem;
      box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
    }
    
    /* ======================================= Application ======================================= */
    .Application {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      padding: 4rem 0rem;
    }
    .Application button{
      color: white;
      background-color: #ef79fa;
      border: none;
      border-radius: 1rem;
      padding: 1rem 4rem;
    }
    
    /* ======================================== Contact ============================================= */
    .Contact {
      background: linear-gradient(to bottom, #ffb6fa, #040000);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      padding: 3rem 0rem;
    }
    .Contact>div{
      width: 80%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      justify-content: center;
      gap: 2rem 0rem;
    }
    .Contact>div>div{
      text-align: center;
      width: 60%;
      margin: auto;
    }
    .Contact>div>div>h1{
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
      color: white;
    }
    .logos{
      text-decoration: none;
      color: white;
      font-size: 2rem;
    }
    .logos:hover{
      color: #e162f1;
    }
    
}

/* ============================================Mobile veiw =========================================*/
@media(min-width : 1px) and (max-width:640px) {
  /* ===============================About me====================================== */
  .hero {
    background: linear-gradient(to bottom, #ffb6fa, #a20097);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 3rem 0rem;
  }
  
  .hero div {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  
  .intro {
    font-size: 1rem;
    font-weight: 300;
    width: 100%;
    text-align: center;
    color: white;
  }
  
  .intro-hl {
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 400;
    color: #e340f1;
  }
  
  .hero img {
    width: 70%;
  }
  
  .heading {
    font-family: 'Lobster', cursive;
    font-size: 2rem;
    color: #ef79fa;
  }
  
  .heading1 {
    font-family: 'Lobster', cursive;
    font-size: 2rem;
    color: black;
  }
  
  
  /* =====================================Gallery======================================= */
  .Gallery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    padding: 3rem 0rem;
  }
  
  .Gallery div {
    width: 90%;
    margin: 0 auto;
  }
  
  .carousel-div {
    box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
  }
  
  .imgslide{
    width: 95%;
    aspect-ratio: 2/3;
    object-fit: contain;
  }
  .Gallery button{
   color: white;
   background-color: #ef79fa;
   border: none;
   border-radius: 1rem;
   padding: 0.7rem 3rem;
  }
  
 
  
  /*=========================================== Menus ===============================================*/
  .Menus {
    background: linear-gradient(to bottom, #ffb6fa, #040000);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    padding: 3rem 0rem;
  }
  
  .Menus>div {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    gap: 3rem 0rem;
  }
  
  .Menus>div>img {
    margin: 0 auto;
    width: 70%;
    border-radius: 1rem;
    box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
  }
  
  /* ======================================= Application ======================================= */
  .Application {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 4rem 0rem;
  }
  .Application button{
    color: white;
    background-color: #ef79fa;
    border: none;
    border-radius: 1rem;
    padding: 1rem 4rem;
  }
  
  /* ======================================== Contact ============================================= */
  .Contact {
    background: linear-gradient(to bottom, #ffb6fa, #040000);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 3rem 0rem;
  }
  .Contact>div{
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    gap: 2rem 0rem;
  }
  .Contact>div>div{
    text-align: center;
    width: 60%;
    margin: auto;
  }
  .Contact>div>div>h1{
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    color: white;
  }
  .logos{
    text-decoration: none;
    color: white;
    font-size: 2rem;
  }
  .logos:hover{
    color: #e162f1;
  }
  

}
